import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed-top navbar navbar-expand-lg navbar-dark shadow-sm bg-white" }
const _hoisted_2 = { class: "container-fluid px-lg-5 h-100 d-flex align-items-center" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          exact: "",
          "exact-active-class": "active",
          class: "navbar-brand",
          to: "/"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: require(`@/assets/img/logo/logo.png`),
              alt: "logo.jpg",
              width: "50"
            }, null, 8, _hoisted_3)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}