
import { defineComponent } from "vue";
import Header from "./layout/header.vue";
import Footer from "@/modules/innovation/components/layout/footer.vue";

export default defineComponent({
  name: "NotFound",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      data: "hey",
    };
  },
});
